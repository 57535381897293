<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteRole($event, null , true)"
    />
    <vs-popup
      title="تفاصيل الدور"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="اسم الدور"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الاسم</label>
                <vs-input
                  placeholder="اسم الدور"
                  v-model="role.name"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
              <ValidationProvider
                name="الاسم العربي"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الاسم العربي</label>
                <vs-input
                  placeholder="الاسم العربي"
                  v-model="role.name_ar"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <ValidationProvider
                name="التفاصيل"
                rules="required"
                v-slot="{ errors }"
              >
                <label>التفاصيل</label>
                <vs-input
                  placeholder="التفاصيل"
                  v-model="role.desc"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>


          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
    <vs-popup
      title="تفاصيل الدور "
      :active.sync="popupActiveDetails"
    >
      <div class="vx-row mt-10">
        <div class="vx-col sm:w-1/4 w-full">
          <h6>الاسم :</h6>
        </div>
        <div class="vx-col sm:w-1/4 w-full mb-2">
          <p>{{ role.name }}</p>
        </div>
      </div>
      <div class="vx-row mt-10">
        <div class="vx-col sm:w-1/4 w-full">
          <h6>الاسم بالعربية :</h6>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-chip color="primary">
            {{ role.name_ar }}
          </vs-chip>
        </div>
      </div>
      <div class="vx-row mt-10">
        <div class="vx-col sm:w-1/4 w-full">
          <h6>الوصف :</h6>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <p>{{ role.desc }}</p>
        </div>
      </div>
      <div class="vx-row mt-5">
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-button
            ycolor="success"
            type="filled"
            @click="popupActiveDetails = false"
          >
            حسناً
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../shared-components/ag-grid/AgTable';
import AgTableBtnCell from '../shared-components/ag-grid/AgTableBtnCell.vue';
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import utilities from "@/app/shared/utilities";
import AgButton from "@/app/shared/shared-components/ag-grid/AgButton";

const roleRepo = SharedRepositoryFactory.get('roleRepository');
export default {
  name: "RoleMain",
  components: {
    AgTable
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      rowData: [],
      role: {
        id: null,
        name: '',
        name_ar: '',
        desc: ''
      },
      isLoading: true,
      popupActiveDetails: false,
      popupActive: false
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',

        },
        {
          headerName: 'الاسم بالعربية',
          field: 'name_ar',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الوصف',
          field: 'desc',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الصلاحيات',
          cellRendererFramework: AgButton,
          field: 'id',
          filter: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'عرض الصلاحيات',
            type: 'gradient',
            click(id) {
              self.rowClicked(id);
            }
          }
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteRole(id, rowIndex);
            },
            editRecord: function (role) {
              self.editRecord(role);
            },
            viewRecordDetails: function (role) {
              self.viewRecordDetails(role);
            },
            actions: ['view', 'edit', 'delete']
          },

        },
      ];
    },
    rowClicked(id) {
      this.$router.push(`/roles/${id}/permissions`);
    },
    deleteRole(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      roleRepo.deleteRole(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllRoles();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },
    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      utilities.initObjectMembers(this.role);
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(role) {
      Object.assign(this.role, role);
      this.openPopup();
    },

    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    onSubmit() {
      if (this.role.id !== null) {
        this.updateRole(this.role);
      } else {
        this.storeRole(this.role);
      }
    },
    storeRole(role) {
      roleRepo.storeRole(this.$objectToFD(role)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },

    updateRole(role) {
      roleRepo.updateRole(this.$objectToFD(role), role.id).then((response) => {
        let index = this.rowData.map((role) => {
          return role.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },

    handleResponse() {
      this.popupActive = false;
    },

    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.role = record;
    },
    fetchAllRoles() {
      roleRepo.fetchAllRoles().then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllRoles();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>

<style scoped>

</style>
