var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ag-table',{attrs:{"grid-options":_vm.gridOptions,"row-data":_vm.rowData},on:{"emitAddNewRecord":function($event){return _vm.createRecord()},"emitDeleteMultipleRecords":function($event){return _vm.deleteRole($event, null , true)}}}),_c('vs-popup',{attrs:{"title":"تفاصيل الدور","active":_vm.popupActive},on:{"update:active":function($event){_vm.popupActive=$event}}},[(_vm.isLoading)?_c('vs-progress',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"name":"اسم الدور","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("الاسم")]),_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"اسم الدور"},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", $$v)},expression:"role.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"name":"الاسم العربي","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("الاسم العربي")]),_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"الاسم العربي"},model:{value:(_vm.role.name_ar),callback:function ($$v) {_vm.$set(_vm.role, "name_ar", $$v)},expression:"role.name_ar"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mt-5"},[_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{attrs:{"name":"التفاصيل","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("التفاصيل")]),_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"التفاصيل"},model:{value:(_vm.role.desc),callback:function ($$v) {_vm.$set(_vm.role, "desc", $$v)},expression:"role.desc"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mt-5"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-2"},[_c('vs-button',{attrs:{"disabled":_vm.isLoading,"ycolor":"success","type":"filled"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._v("\n              حفظ\n            ")])],1)])])]}}])})],1),_c('vs-popup',{attrs:{"title":"تفاصيل الدور ","active":_vm.popupActiveDetails},on:{"update:active":function($event){_vm.popupActiveDetails=$event}}},[_c('div',{staticClass:"vx-row mt-10"},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full"},[_c('h6',[_vm._v("الاسم :")])]),_c('div',{staticClass:"vx-col sm:w-1/4 w-full mb-2"},[_c('p',[_vm._v(_vm._s(_vm.role.name))])])]),_c('div',{staticClass:"vx-row mt-10"},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full"},[_c('h6',[_vm._v("الاسم بالعربية :")])]),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-2"},[_c('vs-chip',{attrs:{"color":"primary"}},[_vm._v("\n          "+_vm._s(_vm.role.name_ar)+"\n        ")])],1)]),_c('div',{staticClass:"vx-row mt-10"},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full"},[_c('h6',[_vm._v("الوصف :")])]),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-2"},[_c('p',[_vm._v(_vm._s(_vm.role.desc))])])]),_c('div',{staticClass:"vx-row mt-5"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-2"},[_c('vs-button',{attrs:{"ycolor":"success","type":"filled"},on:{"click":function($event){_vm.popupActiveDetails = false}}},[_vm._v("\n          حسناً\n        ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }